<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>
            {{ $t("login") }}
          </h1>
        </div>
      </div>
    </section>
    <div class="login-page">
      <div class="text-center">
        <form method="post" @submit.prevent="sendOtp ? save() : send()">
          <div class="page">
            <div class="field" v-if="!sendOtp">
              <label for="phone">{{ $t("Do not have an account?") }}</label>

              <vue-tel-input
                v-model.trim="body.phone"
                v-bind="{
                  mode: 'international',
                }"
                :allCountries="allCountries"
                :class="{ 'is-invalid': isInvalid && !body.phone }"
                :inputOptions="{ placeholder: 'Ihre Telefonnummer' }"
              ></vue-tel-input>
            </div>
            <div class="field" v-if="sendOtp">
              <label for="password">{{ $t("password") }}</label>
              <input
                type="password"
                class="vti__input ppp"
                id="password"
                v-model="body.password"
                :placeholder="$t('password')"
                :class="{ 'is-invalid': isInvalid && !body.password }"
                style="border-color: #bbb solid 1px !important;"
              />
            </div>

            <!-- <router-link to="/forgetPassword" class="frogetPassword">
              {{ $t("forget your password?") }}</router-link
            > -->
            <button
              class="btn btn-primary btn-login mt-3"
              type="submit"
              :disabled="disabledAdd"
            >
              {{ sendOtp ? $t("sign in") : $t("send otp") }}
            </button>
          </div>
        </form>

        <div class="linked">
          <p style="margin-top: 1rem;">
            {{ $t("Don't have an account yet?") }}
          </p>

          <router-link to="/regstar">{{ $t("new registration") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      body: {
        phone: null,
        password: null,
      },
      isInvalid: false,
      disabledAdd: false,
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),

      sendOtp: false,
    };
  },

  methods: {
    send() {
      if (this.body.phone && this.disabledAdd == false) {
        this.disabledAdd = true;

        this.$http.post("auth/sendotp", this.body).then(
          (res) => {
            this.disabledAdd = false;

            this.sendOtp = true;

            this.$swal.fire({
              icon: "success",
              title: this.$t("Done Successfully"),
              text: this.$t("Login Successfully"),
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            this.disabledAdd = false;

            this.$swal.fire({
              icon: "error",
              title: this.$t("Error"),
              text: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    save() {
      if (this.body.phone && this.body.password && this.disabledAdd == false) {
        this.disabledAdd = true;

        this.$http.post("auth/login", this.body).then(
          (res) => {
            this.disabledAdd = false;
            localStorage.userKB = JSON.stringify(res.data.data);

            location.reload();
            this.$swal.fire({
              icon: "success",
              title: this.$t("Done Successfully"),
              text: this.$t("Login Successfully"),
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            this.disabledAdd = false;

            this.$swal.fire({
              icon: "error",
              title: this.$t("Error"),
              text: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  async created() {},
};
</script>

<style>
.ppp {
  background-color: #fff;
  border-radius: 3px;
  padding: 1.05em 1.5em;
  color: #7f8d99;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #bbb;
}
</style>
